<template>
  <div>
    <transition name="fade">
      <choose-login-modal v-show="modal" @closeModal="modal = false"/>
    </transition>
    <transition name="fade">
      <terms-politics-modal v-show="modalTermsPolitics" @closeModal="modalTermsPolitics = false"/>
    </transition>
    <transition name="fade">
      <terms-business-investor-modal v-show="modalTermsBusinessInvestor" @closeModal="modalTermsBusinessInvestor = false"/>
    </transition>

    <div class="main" :class="modal ? 'blur' : ''">
      <nav-bar class="container" @login="modal = true"/>
      <div class="content container">
        <div class="title">
          Seja um Parceiro de Negócios Tutu Digital
        </div>
        <div class="subtitle">
          Preencha os campos abaixo para iniciar seu cadastro
        </div>

        <div class="articles">
          <div class="article mr-6">
            <v-input v-capitalizeName name="nome" v-model="form.nome" :error="errors.first('nome')" label="Nome Completo*" v-validate="'required|min:2'"></v-input>
            <v-input v-lowercase v-trim name="email" v-model="form.email" :error="errors.first('email')" label="E-mail*" v-validate="'required|email'"></v-input>
            <v-input name="telefone" v-model="form.telefone" :error="errors.first('telefone')" label="Telefone*" :mask="['(##) ####-####', '(##) #####-####']" :masked="true" v-validate="'required|min:14|max:15'"></v-input>
            <div class="boxes_group mb-5">
              <v-radio class="text-sm inline" name="tipoPessoa" value="PF" :error="errors.first('tipoPessoa')" v-model="form.tipoPessoa" v-validate="'required:true'">
                  <span>Sou Pessoa Física</span>
              </v-radio>
              <v-radio class="text-sm inline" name="tipoPessoa" value="PJ" :error="errors.first('tipoPessoa')" v-model="form.tipoPessoa" v-validate="'required:true'">
                  <span>Sou Pessoa Jurídica</span>
              </v-radio>
            </div>
            <v-input v-show="form.tipoPessoa == 'PJ'"  name="cnpj" v-model="form.cnpj" :error="errors.first('cnpj')" label="CNPJ*" v-validate="'cnpj'" mask="##.###.###/####-##"></v-input>
            <v-input v-show="form.tipoPessoa == 'PJ'" v-uppercase name="razaoSocial" v-model="form.razaoSocial" :error="errors.first('razaoSocial')" label="Razão Social*" v-validate="'min:2'"></v-input>
            <v-input v-show="form.tipoPessoa == 'PJ'" v-uppercase name="nomeFantasia" v-model="form.nomeFantasia" :error="errors.first('nomeFantasia')" label="Nome Fantasia*" v-validate="'min:2'"></v-input>

            <v-input name="senha" type="password" v-model="form.senha" :error="errors.first('senha')" label="Senha" v-validate="'required|min:6'"></v-input>
            <v-input name="confirmacaoSenha" type="password" v-model="form.confirmacaoSenha" :error="errors.first('confirmacaoSenha')" label="Repetir senha" v-validate="{required: true, min: 6, is: form.senha}"></v-input>
          </div>
          <div class="article">
            <div class="social">
              <p class="social_title"></p>
              <div class="social_icons">

              </div>
            </div>
            <div class="content_checkboxes">
              <v-checkbox @click="!termos ? modalTermsBusinessInvestor = true : modalTermsBusinessInvestor = false" type="checkbox" class="text-sm" name="termos" :error="errors.first('termos')" v-model="termos" v-validate="'required:true'">
                <span> Declaro que li e estou de acordo com os
                <span class="text-secondary">Termos e Condições para Tomadores e Investidores</span></span>
              </v-checkbox>
              <v-checkbox @click="!termosPrivacidade ? modalTermsPolitics = true : modalTermsPolitics = false" class="text-sm my-2" name="termosPrivacidade" :error="errors.first('termosPrivacidade')" v-model="termosPrivacidade" v-validate="'required:true'">
                <span> Declaro que li e estou de acordo com os
                <span class="text-secondary">Termos e Condições de Uso e Política de Privacidade</span></span>
              </v-checkbox>
            </div>
          </div>
        </div>
        <div class="buttons">
          <v-button class="items-center" v-on:click="submit" :loading="loading" >Avançar</v-button>
        </div>
      </div>
      <div class="footer" :class="modal ? 'blur' : ''">
        <p class="footer_text container">
          Copyright © TuTu Digital. Todos os Direitos Reservados. Tutu Digital Tecnologia Ltda – CNPJ 28.304.222/0001-17, Avenida Paulista, 1374 - Andar 11, São Paulo, SP. CEP 01310-100.
          Esse site está em fase pré-operacional e sendo aprimorado pela equipe da TuTu Digital. Até que esse aprimoramento seja concluído, todas as operações serão realizadas
          a partir do contato individualizada da Tutu Digital com cada cliente.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/NavBar.vue'
import ChooseLoginModal from '@/components/Modal/ChooseLoginModal'
import ContactAuthorizationsModal from '@/components/Modal/ContactAuthorizationsModal'
import TermsPoliticsModal from '@/components/Modal/TermsPoliticsModal'
import TermsBusinessInvestorModal from '@/components/Modal/TermsBusinessInvestorModal'
import VInput from '@/components/Inputs/Input'
import VCheckbox from '@/components/Inputs/Checkbox'
import VButton from '@/components/Buttons/Button'
import VRadio from '@/components/Inputs/Radio'

import BePartner from '@/services/Landing/BePartner'
import CNPJ from '@/services/Landing/CNPJ'

export default {
  name: 'be-investor',
  props: ['req'],
  components: {
    NavBar,
    ChooseLoginModal,
    ContactAuthorizationsModal,
    TermsPoliticsModal,
    TermsBusinessInvestorModal,
    VInput,
    VButton,
    VCheckbox,
    VRadio
  },
  data () {
    return {
      modalContact: false,
      modalTermsPolitics: false,
      modalTermsBusinessInvestor: false,
      modal: false,
      loading: false,
      error: null,

      form: {
        nome: '',
        email: '',
        telefone: null,
        codigoConviteCampanha: '',
        cnpj: null,
        razaoSocial : null,
        nomeFantasia: null,
        senha: '',
        confirmacaoSenha: ''
      },

      termos: false,
      termosPrivacidade: false
    }
  },
  methods: {
    async submit () {
      if (!this.loading) {
        console.log(1)
        if (await this.$validator.validateAll()) {
          console.log(2)
          this.loading = true
          try {
            const grecaptcha = await this.$recaptcha('login')
            const data = {
              ...this.form,
              grecaptcha
            }
            const response = (await BePartner.new(data)).data;

            console.log(response);

            if(response.data && response.data.autenticacao){
            this.$store.dispatch('notification', { type: 'success', message: 'Cadastrado com sucesso! Redirecionando...' })

            this.$store.dispatch('setLogin', response.data.autenticacao)

               history.pushState({}, null, '/parceiros/cadastrar/sucesso');
                setTimeout(() => {
                    this.$router.push('/parceiro/painel')
                }, 1000);
            }

          } catch (error) {
            console.log(error)
            this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro. Revise o formulário.' })
            if (error.response.data.erros) {
              Object.entries(error.response.data.erros).forEach(([campo, erro]) => this.errors.add({ field: campo === 'password' ? 'senha' : campo, msg: erro }))
            }
          } finally {
            this.loading = false
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    }
  },
    watch : {
    'form.cnpj': async function(){
      if(!this.loadingName && this.form.cnpj && this.form.cnpj.length >= 8 && (!this.form.razaoSocial || this.form.cnpj.substring(0,8) != this.partCNPJ)){
        this.loadingName = true;
        const res = (await CNPJ.getNameRFB(this.form.cnpj)).data.data;

        if(res){
            this.form.razaoSocial = res.razaoSocial;
            this.form.nomeFantasia = res.nomeFantasia;
            this.partCNPJ = this.form.cnpj.substring(0,8);
          }
        this.loadingName = false;
      }
    },
    'form.tipoPessoa': function(){
      if(this.form.tipoPessoa === "PF"){
        this.form.cnpj = null;
        this.form.razaoSocial = null;
        this.form.nomeFantasia = null;
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.main
  @apply flex flex-col h-full min-h-screen

.content
  @apply mt-8 flex flex-col w-full mb-auto

.title
  @apply text-secondary text-2xl font-bold
  @screen lg
    @apply text-4xl

.subtitle
  @apply text-sm text-gray-700 mb-4
  @screen lg
    @apply text-base mb-6

.articles
  @apply flex flex-col
  @screen lg
    @apply flex-row

.article
  @apply w-full flex flex-col
  @screen lg
    @apply w-1/2 justify-between

.social_title
  @apply text-gray-700 mb-3 text-center
  @screen lg
    @apply text-lg text-left

.social
  @apply flex flex-col mb-6
  @screen lg
    @apply mb-0

.social_icons
  @apply flex justify-center
  @screen lg
    @apply justify-start

.social_facebook
  @apply bg-no-repeat w-8 h-8 mr-4 bg-contain  cursor-pointer
  background-image: url("../../../assets/images/login/facebook.svg")
  @screen lg
    @apply w-12 h-12

.social_google_plus
  @apply bg-no-repeat w-8 h-8 mr-4 bg-contain cursor-pointer
  background-image: url("../../../assets/images/login/google.svg")
  @screen lg
    @apply w-12 h-12

.content_checkboxes
  @apply text-gray-700 text-xs text-justify w-full
  @screen lg
    @apply w-11/12 text-sm

.checkbox
  @apply my-2

.buttons
  @apply flex w-full justify-center
  @screen lg
    @apply justify-start

.btn
  @apply w-40 h-10 rounded-full bg-primary text-white self-end my-4
  @screen lg
    @apply w-48
  &:hover:not([disabled]), &:focus:not([disabled])
    @apply outline-none bg-white text-primary border-primary border-2
  &[disabled]
    @apply bg-gray-600

.footer
  @apply w-full bg-gray-200 flex justify-center py-2

.footer_text
  @apply text-center text-sm text-gray-700 leading-relaxed

.blur
  transition-duration: .25s
  filter: blur(8px)
  -webkit-filter: blur(8px)

</style>
